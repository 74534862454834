import { Listbox } from '@headlessui/react'
import * as React from 'react'
import { HiChevronDown } from 'react-icons/hi'
import { TbLoader } from 'react-icons/tb'

import { c } from '@/utils/etc'
import type { Option } from '@/utils/types/common'

import type { SharedFieldInputProps } from '../field-input/field-input'

export interface StatusInputProps<T> extends SharedFieldInputProps {
  name: string
  disabled?: boolean
  label?: string
  limit?: number
  options?: Option<T>[]
  error?: boolean
  value: Option<T>
  onChange?: (v: Option<T>) => void
  onBlur?: () => void
  onFocus?: () => void
  getColor: (s: T) => string
  labelClassName?: string
  loading?: boolean
}

const StatusInput = <T,>({
  options,
  disabled,
  value,
  onChange,
  getColor,
  labelClassName,
  loading,
}: React.PropsWithChildren<StatusInputProps<T>>) => {
  return (
    <Listbox
      as="div"
      className="relative"
      value={value}
      disabled={loading || disabled}
      onChange={onChange || (() => undefined)}
    >
      <Listbox.Button
        className={c(
          'flex items-center space-x-1 rounded-full border px-2 py-1',
          getColor(value.value)
        )}
      >
        <p
          className={c(
            'font-semibold [color:inherit]',
            labelClassName || 'text-caption-md'
          )}
        >
          {value.label}
        </p>
        {loading && (
          <TbLoader className="w-5 h-5 [color:inherit] animate-spin" />
        )}
        {!loading && !disabled && <HiChevronDown className="h-4 w-4" />}
      </Listbox.Button>

      <Listbox.Options className="absolute left-0 mt-2 min-w-max rounded-lg border border-light-gray-300 bg-white py-1 shadow-lg z-10">
        {options?.map((v) => (
          <Listbox.Option
            key={v.label}
            value={v}
            className={c(
              'cursor-pointer px-4 py-2 text-sm font-medium',
              value.value === v.value
                ? 'bg-primary-300 text-light-gray-50'
                : 'hover:bg-primary-100'
            )}
          >
            {v.label}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  )
}

export default StatusInput
