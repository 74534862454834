import { Popover } from '@headlessui/react'
import { useDebounce } from '@react-hook/debounce'
import { useQuery } from '@tanstack/react-query'
import Image from 'next/image'
import React from 'react'
import { TbX } from 'react-icons/tb'

import type { MustHaveProps } from '@/hocs/with-form'
import GoogleOnWhite from '@/public/img/google_on_white.png'
import { env } from '@/utils/envs'
import * as fetch from '@/utils/fetch'

import type { SharedFieldInputProps } from '../field-input/field-input'
import FieldInput from '../field-input/field-input'

export interface GoogleAddrAutocompleteOption {
  id: string
  name: string
}

interface GoogleAddrInputProps extends MustHaveProps, SharedFieldInputProps {
  label?: string
  value?: GoogleAddrAutocompleteOption
  placeholder?: string
}

const GoogleAddrInput: React.FC<GoogleAddrInputProps> = ({
  name,
  label,
  labelClassName,
  placeholder,
  error,
  value,
  onBlur,
  onChange,
}) => {
  const [search, setSearch] = useDebounce('', 350)
  const [text, setText] = React.useState('')
  const [hasFocus, setHasFocus] = React.useState(false)
  const popoverRef = React.useRef<HTMLDivElement>(null)

  const { data: autocompleteOptions } = useQuery({
    queryKey: ['google-addr-autocomplete', search],
    queryFn: () => {
      const params = new URLSearchParams()
      params.set('lang', env.LANGUAGE)
      params.set('q', search)
      return fetch
        .portal<{ name: string; id: string }[]>(`/maps/autocomplete?${params}`)
        .then(({ data }) => data)
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: Boolean(search),
  })

  const isPopoverOpen = React.useMemo(
    () => Boolean(text && hasFocus && autocompleteOptions?.length),
    [text, hasFocus, autocompleteOptions?.length]
  )

  const handleOnFocus = React.useCallback(() => setHasFocus(true), [])

  const handleSelect = React.useCallback(
    (value: GoogleAddrAutocompleteOption) => () => {
      setSearch(value.name)
      setHasFocus(false)
      setText(value.name)
      onChange?.(value)
    },
    [onChange, setSearch]
  )

  React.useEffect(() => {
    const reset = () => {
      setHasFocus(false)
      setText('')
      onBlur?.()
    }

    const check = (evt: MouseEvent) => {
      if (!hasFocus) return
      if (!popoverRef.current?.contains(evt.target as Node)) {
        reset()
      }
    }

    const checkTab = (evt: KeyboardEvent) => {
      if (evt.code === 'Tab' && hasFocus) {
        reset()
      }
    }

    document.addEventListener('click', check, { passive: true })
    document.addEventListener('keydown', checkTab, { passive: true })

    return () => {
      document.removeEventListener('click', check)
      document.removeEventListener('keydown', checkTab)
    }
  }, [hasFocus, onBlur])

  React.useEffect(() => setSearch(text), [text, setSearch])

  return (
    <Popover ref={popoverRef} className="relative">
      <Popover.Button as={React.Fragment}>
        <FieldInput
          value={hasFocus ? text : value?.name || ''}
          name={name}
          label={label}
          error={error}
          labelClassName={labelClassName}
          inputWrapperClassName="relative"
          placeholder={placeholder}
          className="pr-12"
          onChange={setText}
          onFocus={handleOnFocus}
        >
          {Boolean(value) && (
            <ClearChoice slot="after" onChange={onChange} onBlur={onBlur} />
          )}
        </FieldInput>
      </Popover.Button>

      {isPopoverOpen && (
        <Popover.Panel
          static
          className="absolute inset-x-0 bg-white mt-2 border border-light-gray-400 rounded-lg overflow-hidden z-10"
        >
          {autocompleteOptions?.map(({ id, name }) => (
            <button
              key={id}
              tabIndex={-1}
              type="button"
              className="text-body-sm text-left font-medium text-dark-gray-500 hover:bg-primary-100 w-full px-4 py-2"
              onClickCapture={handleSelect({ id, name })}
            >
              {name}
            </button>
          ))}
          <div className="float-right pr-4 py-2">
            <Image src={GoogleOnWhite} alt="Google logo" />
          </div>
        </Popover.Panel>
      )}
    </Popover>
  )
}

const ClearChoice: React.FC<{
  slot: 'after'
  onChange?: (v: any) => void
  onBlur?: () => void
}> = ({ onChange, onBlur }) => {
  const handleClear = React.useCallback(() => {
    onChange?.(undefined)
    onBlur?.()
  }, [onBlur, onChange])

  return (
    <button
      type="button"
      className="absolute right-0 inset-y-0 flex items-center justify-center group-one px-3"
      onClick={handleClear}
    >
      <div className="p-1 rounded-full group-one-hover:bg-primary-100 group-one-focus:bg-primary-100">
        <TbX className="w-4 h-4 group-one-hover:text-primary-300 group-one-focus:text-primary-300" />
      </div>
    </button>
  )
}

export default GoogleAddrInput
