import React from 'react'
import type { GroupBase, MultiValue, SingleValue } from 'react-select'
import Select from 'react-select'
import type { CreatableProps } from 'react-select/creatable'
import CreatableSelect from 'react-select/creatable'
import type {
  ComponentProps,
  UseAsyncPaginateParams,
} from 'react-select-async-paginate'
import {
  AsyncPaginate as AsyncSelect,
  withAsyncPaginate,
} from 'react-select-async-paginate'

import { c } from '@/utils/etc'
import type { AsyncLoadOptions, Option } from '@/utils/types/common'

import type { SharedFieldInputProps } from '../field-input/field-input'
import { useSelectInput } from './select-input.hook'

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => React.ReactElement

const AsyncCreatableSelect = withAsyncPaginate(
  CreatableSelect
) as AsyncPaginateCreatableType

export interface SelectInputProps<T> extends SharedFieldInputProps {
  name: string
  disabled?: boolean
  label?: string
  limit?: number
  options?: Option<T>[]
  isOptionDisabled?: (option: Option<T>) => boolean
  error?: boolean
  multiple?: boolean
  creatable?: boolean
  pill?: boolean
  value?: Option<T> | Option<T>[]
  placeholder?: string
  loadOptions?: AsyncLoadOptions<T>
  menuPlacement?: 'auto' | 'top' | 'bottom'
  testID?: string
  onChange?: (v: SingleValue<Option<T>> | MultiValue<Option<T>>) => void
  onBlur?: () => void
  onFocus?: () => void
  onCreate?: (inputValue: string) => Option<T> | null
  formatCreateLabel?: (inputValue: string) => React.ReactNode
}

const SelectInput = <T,>(
  props: React.PropsWithChildren<SelectInputProps<T>>
) => {
  const { commonProps } = useSelectInput(props)

  const isAsync = props.loadOptions ? '1' : '0'
  const isCreatable = props.creatable ? '1' : '0'
  const asyncCreatableSignature = isAsync + isCreatable

  const handleOnCreate = React.useCallback(
    (value: string) => {
      const result = props.onCreate?.(value)
      if (!result) return
      props.onChange?.(result)
    },
    [props]
  )

  const Component = React.useMemo(
    () =>
      ({
        // not async not creatable
        '00': <Select {...commonProps} options={props.options} />,
        // async not creatable
        '10': (
          <AsyncSelect
            {...commonProps}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            loadOptions={props.loadOptions!}
            cacheUniqs={[props.loadOptions]}
          />
        ),
        // not async creatable
        '01': (
          <CreatableSelect
            {...commonProps}
            options={props.options}
            onCreateOption={handleOnCreate}
            formatCreateLabel={props.formatCreateLabel}
          />
        ),
        // async creatable
        '11': (
          <AsyncCreatableSelect
            {...commonProps}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            loadOptions={props.loadOptions!}
            cacheUniqs={[props.loadOptions]}
            onCreateOption={handleOnCreate}
            formatCreateLabel={props.formatCreateLabel}
          />
        ),
      }[asyncCreatableSignature]),
    [asyncCreatableSignature, commonProps, props, handleOnCreate]
  )

  return (
    <div className={c(props.containerClassName)}>
      {props.label && (
        <label className={c('form-label', props.error && 'text-danger-400')}>
          {props.label}
        </label>
      )}

      <span data-test-id={props.testID}>{Component}</span>
    </div>
  )
}

export default SelectInput
