import * as React from 'react'

import type { MustHaveProps } from '@/hocs/with-form'
import { c } from '@/utils/etc'

import TextInfo from '../../TextInfo'

export interface TextAreaProps extends MustHaveProps {
  label?: string
  labelTooltip?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  className?: string
  disabled?: boolean
}

const TextArea: React.FCC<TextAreaProps> = ({
  label,
  labelTooltip,
  error,
  containerClassName,
  labelClassName,
  placeholder,
  className,
  passthrough,
  disabled,
  ...props
}) => {
  const id = React.useMemo(() => `${props.name}-f`, [props.name])

  return (
    <div
      className={c('group', error && 'form-field-error', containerClassName)}
    >
      {label && (
        <>
          {labelTooltip ? (
            <TextInfo
              as="label"
              info={labelTooltip}
              className={c('form-label', labelClassName)}
              htmlFor={id}
            >
              {label}
            </TextInfo>
          ) : (
            <label
              htmlFor={id}
              className={c('form-label flex items-center', labelClassName)}
            >
              {label}
            </label>
          )}
        </>
      )}

      <textarea
        className={c('form-field resize-none disabled:resize-none', className)}
        {...props}
        {...(passthrough as any)}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}

export default TextArea
