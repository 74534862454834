import { useRouter } from 'next/router'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useField, useForm } from 'react-final-form'
import { v4 as UUID } from 'uuid'

import { useReadonly } from '@/contexts/readonly.context'
import { useAuth } from '@/hooks/use-auth'
import * as fetch from '@/utils/fetch'
import type { TipoArquivo } from '@/utils/types'
import type { FileRecord } from '@/utils/types/common'

type IFile = {
  file: File
  id?: string
  key?: string
  error?: string
  url?: string | null
}

interface UseMultipleFileProps {
  name: string
  maxFiles: number
  type: TipoArquivo
  upload: boolean
  accept?: string[]
  maxSizePerFile: number
  canInsert?: boolean
  onChange?: ({
    files,
    name,
  }: {
    files: FileRecord[]
    file: FileRecord
    name: string
  }) => void
  onDelete?: (p: { id: string }) => void
}

export const mapMimeFileExt: Record<string, string[]> = {
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'audio/mpeg': ['.mp3'],
  'audio/wav': ['.wav'],
  'audio/ogg': ['.ogg'],
  'video/mp4': ['.mp4'],
  'video/x-msvideo': ['.avi'],
}

export const makeAcceptFromMimes = (mimes: string[] = []) =>
  mimes.reduce(
    (a, b) => ({ ...a, [b]: (a[b] || []).concat(mapMimeFileExt[b]) }),
    {} as Record<string, string[]>
  )

export const mapAcceptToReadableString = (accept: string[]) => {
  const strs = accept.map((a) => mapMimeFileExt[a]).filter((v) => !!v)
  const comma = strs.slice(0, -1)
  return strs.length === 1
    ? strs[0]
    : `${comma.join(', ')} e ${strs.slice(-1)[0]}`
}

export const useMultipleFile = ({
  name,
  maxFiles,
  maxSizePerFile,
  canInsert,
  type,
  onChange,
  upload,
  onDelete,
  accept,
}: UseMultipleFileProps) => {
  const {
    input: { value },
  } = useField(name, { value: true }) as any

  const [files, setFiles] = React.useState<FileRecord[]>([])
  const [filesState, setFilesState] = React.useState<IFile[]>([])

  const { change, blur } = useForm()
  const { readonly } = useReadonly()
  const { context, user } = useAuth()

  const { asPath } = useRouter()
  const id = React.useMemo(() => `${name}-f`, [name])

  const dropzoneGridColumn = React.useMemo(
    () =>
      `span ${maxFiles - filesState?.length} / span ${
        maxFiles - filesState?.length
      }`,
    [maxFiles, filesState]
  )

  const gridTemplateColumns = React.useMemo(
    () => `repeat(${maxFiles}, minmax(0, 1fr))`,
    [maxFiles]
  )

  const updateFile = React.useCallback((id: string, data: any) => {
    setFilesState((old) =>
      old
        .map((item) => {
          return id === item.id ? { ...item, ...data } : item
        })
        .filter(({ error }) => !error)
    )
  }, [])

  const processUpload = React.useCallback(
    (uploadedFile: any) => {
      setFilesState((old) => [...old.concat(uploadedFile)])

      const fd = new FormData()
      const url = '/documents'

      fd.set('types', type)
      fd.append('files', uploadedFile.file)

      if (context) fd.set('enviadoPorPerfil', context)
      if (user?.perfil?.id) fd.set('enviadoPorId', user.perfil.id)

      fetch
        .storage<FileRecord[]>(url, {
          method: 'post',
          body: fd,
        })
        .then(async ({ data }) => {
          onChange && (await onChange({ files, file: data[0], name }))

          updateFile(uploadedFile.id, {
            id: data[0]._id,
            url: data[0].url,
            key: data[0].key,
          })

          setFiles((old) => {
            change(name, [...old.concat(data)])
            return [...old.concat(data)]
          })
        })
        .catch(() => {
          updateFile(uploadedFile.id, {
            error: true,
          })
        })
    },
    [change, context, files, name, onChange, type, updateFile, user?.perfil?.id]
  )

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      blur(name)

      if (filesState.length === maxFiles) return
      if (acceptedFiles.length < 1) return
      if (acceptedFiles.length > maxFiles - filesState.length) return

      const uploaded = acceptedFiles.map((file) => ({
        file,
        id: UUID(),
        key: null,
        url: null,
      }))

      if (upload) {
        uploaded.forEach(processUpload)
      }
    },
    [blur, name, filesState.length, maxFiles, upload, processUpload]
  )

  const del = React.useCallback(
    async (fileArr: IFile) => {
      if (fileArr.url) {
        onDelete &&
          (await onDelete({
            id: fileArr.id as string,
          }))
      }

      setFilesState((old) => [...old.filter((item) => item.id !== fileArr.id)])

      setFiles((old) => {
        change(name, [...old.filter((item) => item.key !== fileArr.key)])
        return [...old.filter((item) => item.key !== fileArr.key)]
      })
    },
    [change, name, onDelete]
  )

  const {
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    disabled: readonly,
    multiple: true,
    accept: makeAcceptFromMimes(accept),
    maxSize: maxSizePerFile,
    maxFiles,
  })

  React.useEffect(() => {
    if (value?.length) {
      const listDocuments: any = []

      value.filter(Boolean).forEach((item: FileRecord) => {
        const fd = new File(['foo'], item.name, { type })

        listDocuments.push({
          file: fd,
          id: item.mongoDbId,
          key: item.key,
          url: item.url,
        })
      })

      setFilesState(listDocuments)
      setFiles(value.filter(Boolean))
    } else {
      setFilesState([])
    }
  }, [asPath, context, type, user.perfil?.id, value])

  return {
    mapAcceptToReadableString,
    gridTemplateColumns,
    dropzoneGridColumn,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    isDragActive,
    filesState,
    readonly: readonly || !canInsert,
    del,
    id,
  }
}
